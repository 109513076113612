<template>
  <div v-loading="isLoading">
    <div
      v-if="person&&person.is_new_ver===PATIENT_SYNC_HIS.isNew"
      class="relative flex justify-between fs-18 mb-3 pl-3 py-3 pr-10"
      style="background-color:#FFEFD9"
    >
      <div class="flex flex-col lg:flex-row gap-4 items-center justify-between w-full">
        <div class="flex items-center gap-4">
          <WarningIcon />
          <div
            class="flex-1 fs-18 break-words"
            style="word-break: break-word;"
            v-html="$t('lbl_his_data_changed')"
          />
        </div>
        <div class="flex items-center mr-10">
          <button
            @click="handleSyncPatient"
            class="fs-14 flex items-center justify-center border-0 focus:outline-none text-white rounded-lg h-10 px-3 py-2"
            style="background-color: #F79009"
          >{{ $t('lbl_update_all') }}</button>
        </div>
      </div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="absolute top-2 lg:top-1/2 transform lg:-translate-y-1/2 right-3 cursor-pointer"
      >
        <path
          d="M13.3138 11.8998L16.8488 8.36382C16.9443 8.27157 17.0205 8.16123 17.0729 8.03922C17.1253 7.91722 17.1529 7.786 17.1541 7.65322C17.1552 7.52044 17.1299 7.38876 17.0796 7.26587C17.0294 7.14297 16.9551 7.03132 16.8612 6.93742C16.7673 6.84353 16.6557 6.76928 16.5328 6.719C16.4099 6.66872 16.2782 6.64342 16.1454 6.64457C16.0126 6.64572 15.8814 6.67331 15.7594 6.72572C15.6374 6.77813 15.5271 6.85431 15.4348 6.94982L11.8988 10.4848L8.36382 6.94982C8.27157 6.85431 8.16123 6.77813 8.03922 6.72572C7.91722 6.67331 7.786 6.64572 7.65322 6.64457C7.52044 6.64342 7.38876 6.66872 7.26587 6.719C7.14297 6.76928 7.03132 6.84353 6.93742 6.93742C6.84353 7.03132 6.76928 7.14297 6.719 7.26587C6.66872 7.38876 6.64342 7.52044 6.64457 7.65322C6.64572 7.786 6.67331 7.91722 6.72572 8.03922C6.77813 8.16123 6.85431 8.27157 6.94982 8.36382L10.4848 11.8988L6.94982 15.4348C6.85431 15.5271 6.77813 15.6374 6.72572 15.7594C6.67331 15.8814 6.64572 16.0126 6.64457 16.1454C6.64342 16.2782 6.66872 16.4099 6.719 16.5328C6.76928 16.6557 6.84353 16.7673 6.93742 16.8612C7.03132 16.9551 7.14297 17.0294 7.26587 17.0796C7.38876 17.1299 7.52044 17.1552 7.65322 17.1541C7.786 17.1529 7.91722 17.1253 8.03922 17.0729C8.16123 17.0205 8.27157 16.9443 8.36382 16.8488L11.8988 13.3138L15.4348 16.8488C15.5271 16.9443 15.6374 17.0205 15.7594 17.0729C15.8814 17.1253 16.0126 17.1529 16.1454 17.1541C16.2782 17.1552 16.4099 17.1299 16.5328 17.0796C16.6557 17.0294 16.7673 16.9551 16.8612 16.8612C16.9551 16.7673 17.0294 16.6557 17.0796 16.5328C17.1299 16.4099 17.1552 16.2782 17.1541 16.1454C17.1529 16.0126 17.1253 15.8814 17.0729 15.7594C17.0205 15.6374 16.9443 15.5271 16.8488 15.4348L13.3138 11.8988V11.8998Z"
          fill="black"
        />
      </svg>
    </div>
    <div class="row">
      <div class="col-lg-5 request-person-info d-flex">
        <div class="person-avatar">
          <span v-if="person && person.user" class="avatar avatar-md avatar-rounded">
            <span
              class="avatar avatar-sm avatar-rounded"
              v-if="person.user.avatar"
              :style="
                'background-image: url(' + getImageURL(person.user.avatar) + ')'
              "
            ></span>
            <span v-if="!person.user.avatar" class="avatar avatar-sm avatar-rounded bg-secondary">
              <span style="color: #fff">
                {{
                person && person.name ? person.name.charAt(0) : ""
                }}
              </span>
            </span>
          </span>
          <span v-if="person && !person.user" class="avatar avatar-md avatar-rounded bg-secondary">
            <span style="color: #fff">
              {{
              person && person.name ? person.name.charAt(0) : ""
              }}
            </span>
          </span>
        </div>
        <div class="person-name m-3">
          <div>
            <p class="m-0 robo-24-500 txt-pri">{{ person && person.name ? person.name : "" }}</p>
          </div>
        </div>
      </div>
      <div class="col-lg-7 text-right" v-if="isDoctor()">
        <button class="btn bg-pri bd-pri text-white ml-2" @click="showModalUpdatePatient">
          <span class="robo-16-500">
            <i class="fa fa-pencil-square-o" style="font-size: 20px" aria-hidden="true"></i>
            {{ $t('lbl_edit') }}
          </span>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6 order-sm-1 col-xl-3 col-xxl-3 mt-3">
        <div class="row mt-2">
          <div class="row mt-2">
            <div class="col-12 pb-0">
              <span class="robo-24-500">{{ $t('lbl_general_health_check') }}</span>
              <br />
              <span
                class="robo-14-400 txt-pri"
                v-if="person && person.stats && person.stats.length"
              >
                {{ $t('lbl_last_updated') }}
                {{ formatStatsDateDMY(person.stats) }}
              </span>
              <div class="row my-2" v-if="person && person.stats">
                <div
                  class="col-lg-6 d-flex align-items-center"
                  v-for="stat in person.stats"
                  :key="stat.id"
                >
                  <p class="robo-14-500 txt-grey-600 mr-1">
                    {{
                    stat.name ? stat.name : "(Mã chỉ số: " + stat.code + ")"
                    }}:
                  </p>
                  <p class="robo-14-500 txt-pri mr-1">{{ stat.value || "---" }}</p>
                  <p class="robo-14-400 txt-pri">{{ stat.unit }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3" v-if="can('view-patient-contact') || isAdmin">
          <div class="row">
            <p class="robo-24-500 col-12">{{ $t('lbl_personal_information') }}</p>
            <div class="row mt-2 border-bottom pb-1">
              <div class="col-6 col-xl-6 robo-16-400">{{ $t('lbl_age') }}</div>
              <div align="right" class="col-6 col-xl-6 robo-16-400">{{ person.age }}</div>
            </div>
            <div class="row mt-2 border-bottom pb-1">
              <div class="col-6 col-xl-6 robo-16-400">{{ $t('lbl_sex') }}</div>
              <div
                align="right"
                class="col-6 col-xl-6 robo-16-400"
              >{{ $t(person.gender === 2 ? "lbl_female" : "lbl_male") }}</div>
            </div>
            <div class="row mt-2 border-bottom pb-1">
              <div class="col-6 col-xl-6 robo-16-400">{{ $t('lbl_job') }}</div>
              <div align="right" class="col-6 col-xl-6 robo-16-400">{{ person.career }}</div>
            </div>
          </div>
          <div class="row">
            <div class="row mt-2 border-bottom pb-1">
              <div class="col-4 col-xl-6 robo-16-400">{{ $t('lbl_phone_number_short') }}</div>
              <div align="right" class="col-8 col-xl-6 robo-16-400">{{ person ? person.phone : "" }}</div>
            </div>
            <div class="row mt-2 border-bottom pb-1">
              <div class="col-4 col-xl-4 robo-16-400">Email</div>
              <div
                align="right"
                class="col-8 col-xl-8 robo-16-400"
              >{{ person ? (person.user ? person.user.email : "") : "" }}</div>
            </div>
            <div class="row mt-2 border-bottom pb-1">
              <div class="col-4 col-xl-4 robo-16-400">{{ $t('lbl_address') }}</div>
              <div align="right" class="col-8 col-xl-8 robo-16-400">{{ getAddress(person) }}</div>
            </div>
            <div class="row mt-2 border-bottom pb-1">
              <div class="col-4 col-xl-4 robo-16-400">{{ $t('BHYT') }}</div>
              <div
                align="right"
                class="col-8 col-xl-8 robo-16-400"
              >{{ person && person.insurance_correct_route === 1 ? 'Đúng tuyến' : person && person.insurance_correct_route === 2 ? 'Trái tuyến' : '' }}</div>
            </div>
          </div>
        </div>
        <div class="row mt-5" v-if="can('view-patient-contact') || isAdmin">
          <div class="row">
            <p class="robo-24-500 col-12" style="color: #344054; margin-bottom: 5px">
              {{ $t('lbl_information_synchronization') }}
              <span
                class="label-approve"
                :class="statusConnectOrgVariant.color"
              >{{ statusConnectOrgVariant.text }}</span>
            </p>
            <div
              v-if="
                patient_connect_request &&
                [2, 3].includes(patient_connect_request.status)
              "
            >
              <div
                class="connect-status"
                :class="{
                  reject: patient_connect_request.status === 3,
                  approve: patient_connect_request.status === 2,
                }"
              >
                <p v-if="patient_connect_request.status === 3">
                  <b>{{ $t('lbl_rejection_reason') }}:</b>
                  {{ patient_connect_request.rejected_reason }}
                </p>
                <template v-if="patient_connect_request.status === 3">
                  <p>
                    <b>{{ $t('lbl_denied_person') }}:</b>
                    {{ patient_connect_request.rejected_by_name }}
                  </p>
                  <p>
                    <b>{{ $t('lbl_time') }}:</b>
                    {{
                    patient_connect_request.rejected_at
                    ? formatDateDDMMYYY(patient_connect_request.rejected_at)
                    : ""
                    }}
                  </p>
                </template>
                <template v-if="patient_connect_request.status === 2">
                  <p>
                    <b>{{ $t('lbl_approver') }}:</b>
                    {{ patient_connect_request.accepted_by_name }}
                  </p>
                  <p>
                    <b>{{ $t('lbl_time') }}:</b>
                    {{
                    patient_connect_request.accepted_at
                    ? formatDateDDMMYYY(patient_connect_request.accepted_at)
                    : ""
                    }}
                  </p>
                </template>
              </div>
            </div>
            <div class="row mt-2 pb-1">
              <div class="col-6 col-xl-6 robo-16-400">{{ $t('lbl_patient_code') }}</div>
              <div
                align="right"
                class="col-6 col-xl-6 robo-16-400"
              >{{ patient_connect_request && patient_connect_request.pid }}</div>
            </div>
            <div class="row mt-2 pb-1">
              <div class="col-6 col-xl-6 robo-16-400">{{ $t('lbl_medical_record_code') }}</div>
              <div
                align="right"
                class="col-6 col-xl-6 robo-16-400"
              >{{ patient_connect_request && patient_connect_request.emr_id }}</div>
            </div>
            <div class="row mt-2 pb-1">
              <div
                class="col-6 col-xl-6 robo-16-400 lh-27"
              >{{ $t('lbl_photo_of_social_healthcare_insurance') }}</div>
              <div align="right" class="col-6 col-xl-6 robo-16-400">
                <button
                  class="btn bd-pri cl-pri view-image"
                  :disabled="
                    !patient_connect_request ||
                    !patient_connect_request.hasOwnProperty('insurance_url')
                  "
                  @click="showImage(patient_connect_request.insurance_url)"
                >
                  {{ $t('lbl_button_view') }}
                  <svg
                    width="20"
                    height="12"
                    viewBox="0 0 20 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.0933 5.7C17.2416 2.1825 13.7583 0 9.99994 0C6.24161 0 2.75827 2.1825 0.906606 5.7C0.856131 5.79462 0.830078 5.89676 0.830078 6C0.830078 6.10324 0.856131 6.20538 0.906606 6.3C2.75827 9.8175 6.24161 12 9.99994 12C13.7583 12 17.2416 9.8175 19.0933 6.3C19.1437 6.20538 19.1698 6.10324 19.1698 6C19.1698 5.89676 19.1437 5.79462 19.0933 5.7ZM9.99994 10.5C7.09411 10.5 4.34411 8.7825 2.75827 6C4.34411 3.2175 7.09411 1.5 9.99994 1.5C12.9058 1.5 15.6558 3.2175 17.2416 6C15.6558 8.7825 12.9058 10.5 9.99994 10.5ZM9.99994 3C9.27474 3 8.56583 3.17595 7.96285 3.50559C7.35987 3.83524 6.8899 4.30377 6.61238 4.85195C6.33486 5.40013 6.26225 6.00333 6.40373 6.58527C6.54521 7.16721 6.89442 7.70176 7.40721 8.12132C7.92001 8.54088 8.57334 8.8266 9.28461 8.94236C9.99587 9.05811 10.7331 8.9987 11.4031 8.77164C12.0731 8.54458 12.6458 8.16006 13.0487 7.66671C13.4516 7.17336 13.6666 6.59334 13.6666 6C13.6666 5.20435 13.2803 4.44129 12.5927 3.87868C11.905 3.31607 10.9724 3 9.99994 3ZM9.99994 7.5C9.63734 7.5 9.28288 7.41203 8.98139 7.2472C8.6799 7.08238 8.44492 6.84811 8.30616 6.57403C8.1674 6.29994 8.13109 5.99834 8.20183 5.70736C8.27257 5.41639 8.44718 5.14912 8.70358 4.93934C8.95997 4.72956 9.28664 4.5867 9.64227 4.52882C9.9979 4.47094 10.3665 4.50065 10.7015 4.61418C11.0365 4.72771 11.3229 4.91997 11.5243 5.16665C11.7257 5.41332 11.8333 5.70333 11.8333 6C11.8333 6.39782 11.6401 6.77936 11.2963 7.06066C10.9525 7.34196 10.4862 7.5 9.99994 7.5Z"
                      :fill="
                        !patient_connect_request ||
                        !patient_connect_request.hasOwnProperty('insurance_url')
                          ? 'white'
                          : '#20419b'
                      "
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div class="row mt-2 pb-1">
              <div class="col-6 col-xl-6 robo-16-400 lh-27">{{ $t('lbl_photo_of_identity_card') }}</div>
              <div align="right" class="col-6 col-xl-6 robo-16-400">
                <button
                  class="btn bd-pri cl-pri view-image"
                  :disabled="
                    !patient_connect_request ||
                    (!patient_connect_request.hasOwnProperty(
                      'national_id_url01'
                    ) &&
                      !patient_connect_request.hasOwnProperty(
                        'national_id_url02'
                      ))
                  "
                  @click="openModal('ModalViewIdentityCard')"
                >
                  {{ $t('lbl_button_view') }}
                  <svg
                    width="20"
                    height="12"
                    viewBox="0 0 20 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.0933 5.7C17.2416 2.1825 13.7583 0 9.99994 0C6.24161 0 2.75827 2.1825 0.906606 5.7C0.856131 5.79462 0.830078 5.89676 0.830078 6C0.830078 6.10324 0.856131 6.20538 0.906606 6.3C2.75827 9.8175 6.24161 12 9.99994 12C13.7583 12 17.2416 9.8175 19.0933 6.3C19.1437 6.20538 19.1698 6.10324 19.1698 6C19.1698 5.89676 19.1437 5.79462 19.0933 5.7ZM9.99994 10.5C7.09411 10.5 4.34411 8.7825 2.75827 6C4.34411 3.2175 7.09411 1.5 9.99994 1.5C12.9058 1.5 15.6558 3.2175 17.2416 6C15.6558 8.7825 12.9058 10.5 9.99994 10.5ZM9.99994 3C9.27474 3 8.56583 3.17595 7.96285 3.50559C7.35987 3.83524 6.8899 4.30377 6.61238 4.85195C6.33486 5.40013 6.26225 6.00333 6.40373 6.58527C6.54521 7.16721 6.89442 7.70176 7.40721 8.12132C7.92001 8.54088 8.57334 8.8266 9.28461 8.94236C9.99587 9.05811 10.7331 8.9987 11.4031 8.77164C12.0731 8.54458 12.6458 8.16006 13.0487 7.66671C13.4516 7.17336 13.6666 6.59334 13.6666 6C13.6666 5.20435 13.2803 4.44129 12.5927 3.87868C11.905 3.31607 10.9724 3 9.99994 3ZM9.99994 7.5C9.63734 7.5 9.28288 7.41203 8.98139 7.2472C8.6799 7.08238 8.44492 6.84811 8.30616 6.57403C8.1674 6.29994 8.13109 5.99834 8.20183 5.70736C8.27257 5.41639 8.44718 5.14912 8.70358 4.93934C8.95997 4.72956 9.28664 4.5867 9.64227 4.52882C9.9979 4.47094 10.3665 4.50065 10.7015 4.61418C11.0365 4.72771 11.3229 4.91997 11.5243 5.16665C11.7257 5.41332 11.8333 5.70333 11.8333 6C11.8333 6.39782 11.6401 6.77936 11.2963 7.06066C10.9525 7.34196 10.4862 7.5 9.99994 7.5Z"
                      :fill="
                        !patient_connect_request ||
                        (!patient_connect_request.hasOwnProperty(
                          'national_id_url01'
                        ) &&
                          !patient_connect_request.hasOwnProperty(
                            'national_id_url02'
                          ))
                          ? 'white'
                          : '#20419b'
                      "
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div v-if="!isDisableButtonApprove" class="row mt-4">
            <div class="col-6 col-xl-6 robo-16-400">
              <button
                class="btn bd-pri w-100 cl-pri"
                @click="openModal('rejectModal')"
                :disabled="isDisableButtonApprove"
              >
                <span class="robo-16-500">{{$t('lbl_reject')}}</span>
              </button>
            </div>
            <div class="col-6 col-xl-6 robo-16-400">
              <button
                class="btn bg-pri bd-pri text-white ml-2 w-100"
                @click="openModal('approveModal')"
                :disabled="isDisableButtonApprove"
              >
                <span class="robo-16-500">{{ $t('lbl_accept') }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 order-sm-3 order-xl-2 col-xl-6 col-xxl-7">
        <div class="row">
          <div class="robo-24-500 col-12 mt-3">
            <p class="mb-0 robo-24-500">{{ $t('lbl_list_of_health_records') }}</p>
          </div>
          <div class="mb-3">
            <table
              class="table table-vcenter table-mobile-md card-table table-scroll"
              style="box-sizing: border-box; height: 500px"
            >
              <tbody v-if="diseases && diseases.count" id="diseases" class="ml-2 mr-2 h-100">
                <tr
                  class="my-2 row-contact bg-bold-item cursor-pointer radius-10"
                  v-for="disease in diseases.data"
                  @click="
                    gotoDisease(
                      disease && disease.id,
                      disease && disease.doctor_read
                    )
                  "
                  :key="disease.id"
                >
                  <td data-label="Hồ sơ" style="text-transform: capitalize">
                    <p class="mb-0 ml-2">{{ disease ? disease.diseases_name : "" }}</p>
                  </td>
                  <td>
                    {{ $t('lbl_last_updated') }}:
                    {{
                    disease && disease.updated_at
                    ? formatHMDMY(disease.updated_at)
                    : ""
                    }}
                  </td>
                </tr>
              </tbody>
              <tbody v-if="!diseases || !diseases.total" id="diseases" class="ml-2 mr-2 h-100">
                <tr>
                  <td colspan="2">
                    <div class="col-sm-6 offset-sm-3 pt-3 pb-5 text-center">
                      <img
                        src="../../../public/assets/images/not-found-search.png"
                        class="img-responsive p-0"
                        alt
                      />
                      <p class="robo-16-400 text-black">{{ $t('lbl_no_record_yet') }}</p>
                      <!-- <button v-if="can('create-new-patient')" class="btn-xl btn--blue" type="button" @click="onShowModalCarePlan(true)"><span class="fa fa-plus mr-2"></span> Thêm mới</button> -->
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- <AppPagination :paginationProps="pagination" @onChangePagination="onRefresh" /> -->
            <!-- <Pagination
              v-if="diseases && diseases.count && !loading"
              :items="diseases"
              :current_page="current_page"
              @onRefresh="onRefresh"
            ></Pagination>-->
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 order-sm-2 order-xl-3 col-xl-3 col-xxl-2">
        <div class="row mt-2">
          <div class="col-12 mt-2">
            <p class="robo-24-500">{{ $t('lbl_recent_schedules') }}</p>
          </div>
          <div style="height: 600px; overflow-x: hidden; overflow-y: auto">
            <div id="list_app">
              <div
                class="col-sm-4 mt-2 row-cases ml-1"
                style="
                  background-color: #ebf1ff;
                  padding: 24px 12px;
                  border-radius: 15px;
                  width: 245px;
                "
                v-if="appoiments.total === 0"
              >
                <div class="d-flex py-1 align-items-center ml-2">
                  <div class="flex-fill ml-2">
                    <p class="p-0 m-0 robo-16-500">
                      <span class="txt-pri">{{ $t('lbl_no_appointment_yet') }}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="col-sm-12 mt-2 row-cases mr-2"
                v-for="(item, index) in appoiments.data"
                :key="index"
                style="
                  background-color: #ebf1ff;
                  padding: 24px 12px;
                  border-radius: 15px;
                  min-width: 245px;
                "
                @click="goToDetailAppointment(item.id)"
              >
                <div class="d-flex py-1 align-items-center ml-2">
                  <span>
                    <span v-if="item.method === 1" class="avatar avatar-rounded icon-walking"></span>
                    <span v-if="item.method === 2" class="avatar avatar-rounded icon-video"></span>
                  </span>
                  <div class="flex-fill ml-2">
                    <p class="p-0 m-0">
                      <span class="robo-16-400" v-if="isDoctor()">
                        {{
                        item && item.start_time
                        ? formatDateDMY(item.start_time)
                        : ""
                        }}
                      </span>
                      <br />
                      <span class="robo-16-400" v-if="isAdmin()">
                        {{
                        item && item.start_time
                        ? formatDateDDMMYYY(item.start_time)
                        : ""
                        }}
                      </span>
                      <br />
                      <span class="robo-16-500" v-if="isDoctor()">
                        {{
                        item.doctor.name
                        }}
                      </span>
                      <span class="robo-16-500" v-if="isAdmin()">
                        <span
                          v-if="item.status"
                          :class="getApptStatus(item.status).class"
                        >{{ getApptStatus(item.status).text }}</span>
                      </span>
                      <br />
                      <span class="robo-16-500" v-if="isAdmin()">
                        {{
                        item.doctor_id
                        }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalPatientInfo ref="ModalPatientInfo" @refresh="getPersonInfo" />
    <ModalViewIdentityCard
      v-if="patient_connect_request"
      ref="ModalViewIdentityCard"
      :front-side-url="patient_connect_request.national_id_url01"
      :back-side-url="patient_connect_request.national_id_url02"
    />

    <RejectModal ref="rejectModal" @reject="reject"></RejectModal>
    <ApproveModal ref="approveModal" @confirm="approve"></ApproveModal>

    <CoolLightBox
      v-if="images"
      :items="images"
      :index="image_index"
      :useZoomBar="true"
      @close="image_index = null"
    ></CoolLightBox>
  </div>
</template>
<script>
import appUtils from '../../utils/appUtils'
import PersonForm from '../../components/PersonForm'
import Pagination from '../../components/Pagination'
import _ from 'lodash'
import RejectModal from '../../components/Person/RejectModal.vue'
import ApproveModal from '../../components/Person/ApproveModal.vue'
import ModalViewIdentityCard from '../../components/Person/ModalViewIdentityCard.vue'
import ModalPatientInfo from '../../components/Patient/ModalPatientInfo.vue'
import { mixinGetPersonInfo } from '@/utils/mixinGetPersonInfo'
import { PATIENT_SYNC_HIS } from '../../utils/constants'
import { WarningIcon } from '@/components/Icon'

export default {
  name: 'ContactInfo',
  components: {
    PersonForm,
    Pagination,
    RejectModal,
    ApproveModal,
    ModalViewIdentityCard,
    ModalPatientInfo,
    WarningIcon
  },
  mixins: [mixinGetPersonInfo],
  data () {
    return {
      root_type: 1,
      person_id: 0,
      person: {},
      diseases: [],
      appoiments: [],
      edit_birth: '',
      edit_person: {},
      loading: false,
      user: null,
      clinic: null,
      appointment: null,
      type: 'person-info',
      current_page: 1,
      images: [],
      image_index: null,
      patient_connect_request: {},
      isDisableButtonApprove: true,
      PATIENT_SYNC_HIS,
      isLoading: false,
      pagination: {
        currentPage: 1,
        pageSize: 10,
        totalItems: 0
      }
    }
  },
  computed: {
    statusConnectOrgVariant () {
      let label_color
      let lable_text
      switch (this.patient_connect_request?.status) {
        case 1:
          label_color = 'bc-green'
          lable_text = this.$t('lbl_connection_required')
          break
        case 2:
          label_color = 'bc-green'
          lable_text = this.$t('lbl_connected')
          break
        case 3:
          label_color = 'bc-red'
          lable_text = this.$t('lbl_denied')
          break
        default:
          label_color = 'bc-yellow'
          lable_text = this.$t('lbl_not_connected')
          break
      }
      return { color: label_color, text: lable_text }
    }
  },
  created () {
    let self = this
    this.loading = true
    self.person_id = self.$route?.params?.id
    self.type = self.$route.query.type
    if (appUtils.isDoctor()) {
      self.getContactDetail()
    } else if (appUtils.isAdmin()) {
      self.getPersonDetailAdmin()
    }
    self.getAppointments()
    self.user = appUtils.getLocalUser()
    self.clinic = this.$globalClinic
    window.$(document).ready(function () {
      window.$('#modal__edit').on('hide.bs.modal', function (e) {
        self.err_name = ''
        self.err_phone = ''
        self.err_gender = ''
        self.err_year = ''
        self.err_address = ''
      })
    })
    setTimeout(() => {
      this.sendAmplitude('View Contact Detail', {
        'doctor id':
          this.user && this.user.doctor && this.user.doctor.id
            ? this.user.doctor.id
            : '',
        'doctor name': this.user && this.user.name ? this.user.name : '',
        'workspace id': this.clinic && this.clinic.id ? this.clinic.id : '',
        'workspace name':
          this.clinic && this.clinic.name ? this.clinic.name : '',
        timestamp: window.moment(new Date()).format('HH:mm DD-MM-YYYY'),
        'patient id':
          this.$route && this.$route.params && this.$route?.params?.id
            ? this.$route?.params?.id
            : '',
        'patient name': this.person && this.person.name ? this.person.name : ''
      })
    }, 100)
    this.loading = false
  },
  watch: {
    '$route.query.type': {
      handler: function () {
        let self = this
        self.person_id = self.$route?.params?.id
        // self.onRefresh()
      },
      deep: true
    }
  },
  methods: {
    async getPatientConnectRequestData () {
      try {
        let res = await this.$rf
          .getRequest('DoctorRequest')
          .getPatientConnectRequest(this.person_id, { ws_id: this.clinic.id })
        this.patient_connect_request = res.data.patient_connect_request
        this.person = {
          ...this.person,
          insurances: res.data?.insurances || []
        }
        this.isDisableButtonApprove =
          !this.patient_connect_request ||
          (this.patient_connect_request.hasOwnProperty('status') &&
            this.patient_connect_request.status !== 1)
      } catch (e) {
        console.log(e)
        this.$toast.open({
          message: this.$t('lbl_fail_to_connect__please_try_again'),
          type: 'error'
        })
      }
    },
    async reject (reason) {
      try {
        await this.$rf
          .getRequest('DoctorRequest')
          .rejectConvertPersonToPatient(this.patient_connect_request?.id, {
            rejected_reason: reason
          })
        this.getPatientConnectRequestData()
        this.$toast.open({
          message: this.$t('lbl_complete_to_deny'),
          type: 'success'
        })
      } catch (e) {
        console.log(e)
        this.$toast.open({
          message: this.$t('lbl_fail_to_connect__please_try_again'),
          type: 'error'
        })
      }
    },
    async approve () {
      try {
        await this.$rf
          .getRequest('DoctorRequest')
          .acceptConvertPersonToPatient(this.patient_connect_request?.id)
        this.getPatientConnectRequestData()
        this.$toast.open({
          message: this.$t('lbl_complete_to_approve'),
          type: 'success'
        })
      } catch (e) {
        console.log(e)
        this.$toast.open({
          message: this.$t('lbl_fail_to_connect__please_try_again'),
          type: 'error'
        })
      }
    },
    showImage (link) {
      this.images = [link]
      this.image_index = 0
    },
    getPersonInfo () {
      if (appUtils.isDoctor()) {
        this.getContactDetail()
      } else if (appUtils.isAdmin()) {
        this.getPersonDetailAdmin()
      }
    },
    isDoctor () {
      return appUtils.isDoctor()
    },
    isAdmin () {
      return appUtils.isAdmin()
    },
    changeType (type) {
      let self = this
      self.type = type
      self.$router.replace({ query: { type: type } }).catch(() => {})
    },
    async getContactDetail (num) {
      let self = this
      if (self.person_id) {
        let clinic_id = this.$clinicId
        try {
          let resp = await self.$rf
            .getRequest('DoctorRequest')
            .getPersonDetail(self.person_id)
          console.log(resp)
          if (resp && resp.data) {
            self.person = resp.data
            var obj_birthday = {}
            if (self.person.birthday) {
              var birthday = self.person.birthday.includes('-')
                ? self.person.birthday.split('-')
                : self.person.birthday.split('/')
              obj_birthday = {
                day: birthday[2],
                month: parseInt(birthday[1]),
                year: birthday[0]
              }
              self.edit_birth = obj_birthday
            } else {
              obj_birthday = {
                day: '',
                month: '',
                year: ''
              }
            }
            self.edit_person = self.person
            //
            var params = {
              limit: num || 10,
              sort: 'updated_at',
              sort_by: 'desc',
              page: self.current_page || 1,
              is_accepted: 1,
              clinic_id: clinic_id,
              person_id: resp.data.id
            }
            let result = await self.$rf
              .getRequest('DoctorRequest')
              .getPersonDiseases(params)
            self.diseases = result.data
            //
            this.getPatientConnectRequestData()
          } else {
            self.$router.push({ paht: '/doctor/contact-management' })
          }
        } catch (e) {
          console.log(e)
        } finally {
        }
      } else {
        self.$router.push({ paht: '/doctor/contact-management' })
      }
    },
    getApptStatus (stt) {
      let statuses = []
      statuses[1] = {
        class: 'text--yellow bg--yellow-corl',
        text: this.$t('lbl_pending')
      }
      statuses[2] = {
        class: 'text--green bg--green-corl',
        text: this.$t('lbl_confirm_examination')
      }
      statuses[3] = { class: 'text--red bg--red-corl', text: 'Bác sĩ từ chối' }
      statuses[4] = {
        class: 'text--yellow bg--yellow-corl',
        text: this.$t('lbl_pending')
      }
      statuses[5] = {
        class: 'text--green bg--green-corl',
        text: this.$t('lbl_confirm_examination')
      }
      statuses[6] = {
        class: 'text--red bg--red-corl',
        text: this.$t('lbl_deny_exemination')
      }
      return statuses[stt] ? statuses[stt] : statuses[1]
    },
    async getPersonDetailAdmin (num) {
      let self = this
      try {
        if (!self.person_id) return
        let resp = await self.$rf
          .getRequest('AdminRequest')
          .getPersonByID(self.person_id)
        if (resp && resp.data) {
          self.person = resp.data
          var prms = {
            limit: num || 10,
            sort: 'updated_at',
            sort_by: 'desc',
            page: self.current_page || 1,
            person_id: self.person.id
          }
          let result = await self.$rf
            .getRequest('AdminRequest')
            .getPersonsDiseases(prms)
          self.diseases = result.data
        }
      } catch (e) {
        // statements
        console.log(e)
      }
    },
    async getAppointments (num) {
      let self = this
      try {
        var params = {
          limit: num || 1000,
          sort: 'start_time',
          sort_by: 'desc',
          clinic_id: this.$clinicId,
          person_id: self.person_id
        }
        let result = null
        if (self.isDoctor()) {
          result = await self.$rf
            .getRequest('DoctorRequest')
            .getAppointments(params)
        } else if (self.isAdmin()) {
          params.clinic_id = null
          result = await self.$rf.getRequest('AdminRequest').getAppt(params)
        }
        self.appoiments = result.data
      } catch (e) {
      } finally {
      }
    },
    getBreadcrumbData (name) {
      var data = {
        icon: 'contact.svg',
        pages: [
          {
            text: 'Danh sách bệnh nhân',
            active: false,
            path: '/doctor/contact-management',
            prev: false
          },
          { text: 'Chi tiết bệnh nhân', active: true }
        ]
      }
      return data
    },
    formatDateDMY (date_time) {
      return window.moment(date_time).format('DD/MM/YYYY')
    },
    formatStatsDateDMY (stats) {
      if (!stats || !stats.length) return
      const array = _.sortBy(stats, item => {
        return new Date(item.updated_at)
      })
      return this.formatDateDMY(_.last(array).updated_at)
    },
    onRefresh () {
      this.getContactDetail()
      this.getAppointments()
    },
    getImageURL (path) {
      return process.env.VUE_APP_BASE_IMAGE_URL + 'storage/' + path
    },
    seeMore () {
      let self = this
      if (self.appoiments.total !== self.appoiments.count) {
        var num = self.appoiments.count + 10
        self.getAppointments(num)
      }
    },
    goToDetailAppointment (id) {
      if (this.isDoctor) {
        // this.$router.push({ path: `/doctor/appointment/${id}` })
        this.$router.push({ path: `/doctor/patient-reception/${id}?type=1` })
      }
    },
    async onUpdatePerson (data) {
      let self = this
      try {
        // alert(JSON.stringify(data))
        var params = {
          id: data.id,
          name: data.name,
          clinic_id: this.$clinicId,
          gender: data.gender,
          birthday: data.birthday,
          address: data.address,
          phone: data.phone,
          cmnd: data.cmnd
        }
        self.showModalPerson(false)
        await self.$rf
          .getRequest('DoctorRequest')
          .postDoctorEdit(params.id, params)
        self.$toast.clear()
        self.$toast.open({
          message: 'Cập nhật thành công',
          type: 'success'
        })
        self.getContactDetail()
        setTimeout(() => {
          this.sendAmplitude('Edit Contact', {
            'doctor id':
              this.user && this.user.doctor && this.user.doctor.id
                ? this.user.doctor.id
                : '',
            'doctor name': this.user && this.user.name ? this.user.name : '',
            'workspace id': this.clinic && this.clinic.id ? this.clinic.id : '',
            'workspace name':
              this.clinic && this.clinic.name ? this.clinic.name : '',
            timestamp: window.moment(new Date()).format('HH:mm DD-MM-YYYY'),
            'patient id':
              this.$route.params && this.$route?.params?.id
                ? this.$route?.params?.id
                : '',
            'patient name':
              this.person && this.person.name ? this.person.name : ''
          })
        }, 100)
      } catch (e) {
        setTimeout(() => {
          this.sendAmplitude('Error Edit Contact', {
            'doctor id':
              this.user && this.user.doctor.id ? this.user.doctor.id : '',
            'doctor name': this.user && this.user.name ? this.user.name : '',
            'workspace id': this.clinic && this.clinic.id ? this.clinic.id : '',
            'workspace name':
              this.clinic && this.clinic.name ? this.clinic.name : '',
            timestamp: window.moment(new Date()).format('HH:mm DD-MM-YYYY'),
            'patient id':
              this.$route.params && this.$route?.params?.id
                ? this.$route?.params?.id
                : '',
            'patient name':
              this.person && this.person.name ? this.person.name : '',
            message: e
          })
        }, 100)
      }
    },
    voiceCall (user) {
      this.$router.push({ path: `/call/${user.id}` })
    },
    videoCall (user) {
      this.$router.push({ path: `/call/${user.id}?videocall=1` })
    },
    editPerson (person) {
      let self = this
      var obj_birthday = {}
      if (person.birthday) {
        var birthday = person.birthday.includes('-')
          ? person.birthday.split('-')
          : person.birthday.split('/')
        obj_birthday = {
          day: parseInt(birthday[2]),
          month: parseInt(birthday[1]),
          year: parseInt(birthday[0])
        }
      } else {
        obj_birthday = {
          day: '',
          month: '',
          year: ''
        }
      }
      self.edit_birth = obj_birthday
      self.showModalPerson(true)
    },
    showModalPerson (show) {
      window.$('#modal__edit').modal(show ? 'show' : 'hide')
    },
    gotoDisease (id) {
      let self = this
      if (self.isDoctor()) {
        self.$router.push({ path: `/doctor/diseases/${id}` }).catch(_ => {})
      } else if (self.isAdmin()) {
        self.$router
          .push({ path: `/admin/diseases/${id}/detail` })
          .catch(_ => {})
      }
    },
    formatDateDDMMYYY (time) {
      return window.moment(time).format('HH:mm - DD/MM/YYYY ')
    },
    formatHMDMY (date_time) {
      return window.moment(date_time).format('HH:mm DD/MM/YY')
    },
    openModal (modalId) {
      this.$refs[modalId].openDialog(this.person)
    },
    showModalUpdatePatient () {
      this.$refs.ModalPatientInfo.openDialog(
        true,
        this.$t('lbl_edit_patient'),
        this.person
      )
    },
    async putSyncPatient () {
      try {
        this.isLoading = true
        await this.$rf
          .getRequest('DoctorRequest')
          .putSyncPatient(this.person?.doc_no)
        this.$toast.open({
          message: this.$t('lbl_updated_successfully'),
          type: 'success'
        })

        this.getContactDetail()
      } catch (error) {
        this.$toast.open({
          message: this.$t('lbl_errors_in_the_synchronization'),
          type: 'warning'
        })

        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    handleSyncPatient () {
      this.$confirm(
        this.$t(
          'lbl_please_confirm_to_synchronize_patient_s_information_from_his'
        ),
        {
          confirmButtonText: this.$t('lbl_confirm')
        }
      )
        .then(_ => {
          this.putSyncPatient()
        })
        .catch(_ => {})
    }
  }
}
</script>
<style scoped lang="scss">
button:disabled {
  color: white !important;
}
.row-cases {
  background-color: #f2f5fa !important;
  cursor: pointer;
  border-radius: 10px;
}

.cases-next {
  color: #b6c2cf;
}

.row-cases:hover {
  background-color: #ebf1ff !important;
  cursor: pointer;
}

.row-cases:hover .cases-next {
  color: black;
}

.border-top-left {
  border-left: 1px solid #b6c2cf;
  border-top: 1px solid #b6c2cf;
}

.split-content {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ml-10px {
  margin-left: 10px;
}

.p-10px {
  padding: 10px;
}

.icon-walking {
  background-image: url('../../../public/assets/images/icon/white-walking.svg');
  background-repeat: no-repeat;
  background-size: 20px 26px;
  background-color: #0e7c71;
  height: 36px;
  width: 36px;
}

.icon-video {
  background-image: url('../../../public/assets/images/icon/white_video.svg');
  background-repeat: no-repeat;
  background-size: 20px 15px;
  background-color: #20419b;
  background-origin: center;
  background-position: center;
  height: 36px;
  width: 36px;
}

.icon-detail {
  background-image: url('../../../public/assets/images/icon/next.svg');
  background-repeat: no-repeat;
  background-size: 14px 7px;
  background-color: #20419b;
  background-origin: center;
  background-position: center;
  height: 7px;
  width: 14px;
}

.bg-bold-item {
  background-color: #e7eaf5 !important;
}
.cl-pri {
  color: #20419b !important;
}
.view-image {
  padding: 2px 16px;
  svg {
    margin-right: 0px;
    margin-left: 5px;
  }
}
.lh-27 {
  line-height: 27px;
}
.label-approve {
  white-space: nowrap;
  font-size: 12px;
  font-weight: 600;
  padding: 2px 15px;
  border: 1px solid red;
  border-radius: 10px;
  &.bc-green {
    border-color: #209b3b;
  }
  &.bc-yellow {
    border-color: #f9b915;
  }
  &.bc-red {
    border-color: #ff0d0d;
  }
}
::v-deep .el-dialog {
  .el-dialog__title {
    color: #20419b;
  }
  .el-dialog__body {
    padding-top: 10px;
  }
}
.connect-status {
  &.reject {
    border: 1px solid #ff0d0d;
    color: #ff0d0d;
  }
  &.approve {
    border: 1px solid #209b3b;
    color: #209b3b;
  }
  border-radius: 4px;
  margin: 0px;
  padding: 5px 10px 0px;
  margin-right: 15px;
  p {
    margin-bottom: 5px;
  }
}
</style>
