<template>
  <div class="telemedicine-management contact-list pr-3">
    <div class="card radius-10 pt-2 pl-4 pr-4 border-0">
      <div class="table-responsive">
        <table class="table table-vcenter table-mobile-md card-table table-scroll">
          <thead class="d-none d-md-table">
            <tr>
              <th>Nhóm thông tin</th>
              <!-- <th class="text-sm-center">Ngày cập nhật</th> -->
              <th class="text-sm-center"></th>
              <th class="text-sm-center"></th>
            </tr>
          </thead>
          <tbody
            v-if="groups && groups.count && is_loaded"
            id="contacts"
            :class="groups && groups.count == 10 ? 'h-100' : ''"
          >
            <tr
              class="mt-2 table-tr"
              v-for="(group, index) in groups.data"
              @click="goToDetail(group.id, group.name)"
              style="height: 70px;"
              :key="'gr'+index"
            >
              <td data-label="Bệnh sử">
                <span class="robo-20-500 pl-2">{{group && group.name}}</span>
              </td>
              <!-- <td data-label="Ngày cập nhật"><span class="robo-12-400">12/07/2021</span></td> -->
              <td
                class="text-sm-center"
                style="text-transform: capitalize;"
              ></td>
              <td class="text-sm-center"></td>
            </tr>
            <tr>
              <td
                colspan="3"
                v-if="groups && !groups.data && is_loaded"
              >
                <div class="col-sm-4 offset-sm-4 pt-3 pb-5 text-center">
                  <img
                    src="../../../public/assets/images/not-found-search.png"
                    class="img-responsive p-0"
                    alt=""
                  >
                  <p class="robo-16-400 text-black">{{search ? 'HODO không tìm thấy kết quả phù hợp' : 'Bạn chưa có bệnh nhân thân thiết nào' }}</p>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-if="!is_loaded">
            <tr
              v-for="i in 10"
              :key="'loading' + i"
            >
              <td>
                <div class="skeleton-avatar avatar-sm avatar-rounded">
                </div>
              </td>
              <td>
                <div class="skeleton-line"></div>
              </td>
              <td>
                <div class="skeleton-line"></div>
              </td>
              <td>
                <div class="skeleton-line"></div>
              </td>
              <td>
                <div class="skeleton-line"></div>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- <Pagination v-if="contacts && contacts.count && is_loaded"  :items="contacts" :current_page="current_page" @onRefresh="onRefresh"></Pagination>
                <div v-if="contacts && !contacts.count && !loading">
                    <div class="col-sm-4 offset-sm-4 pt-3 pb-5 text-center overflow-hidden">
                        <img src="../../../public/assets/images/not-found-search.png" class="img-responsive p-0" alt="">
                        <p class="robo-16-400 text-black">{{search ? 'HODO không tìm thấy liên hệ mà bạn tìm' : 'Chưa có liên hệ khám nào!' }}</p>
                        <button class="btn-xl btn--blue" type="button" @click="onShowModalAddPerson(true)"><span class="fa fa-plus mr-2"></span> Thêm liên lạc</button>
                    </div>
                </div> -->
      </div>
      <br>
    </div>
  </div>
</template>
<script>
import appUtils from '../../utils/appUtils'
import Pagination from '../../components/Pagination'
export default {
  components: { Pagination },
  name: 'PersonalHealthInformation.vue',
  computed: {
  },
  data () {
    return {
      groups: null,
      is_loaded: true,
      search: '',
      params: {
        limit: '',
        sort: '',
        sort_by: '',
        search: '',
        page: 1
      },
      type: 'contact-doctor',
      sort: 0,
      loading: false,
      user: null,
      clinic: null,
      list_total_request: null,
      load_end: false,
      current_page: 1,
      person_id: 0
    }
  },
  mounted () {
    let self = this
    self.getGroupQuestion()
    self.person_id = self.$route?.params?.id
  },
  methods: {
    isDoctor () {
      return appUtils.isDoctor()
    },
    isAdmin () {
      return appUtils.isAdmin()
    },
    async getGroupQuestion () {
      let self = this
      try {
        let res = await self.$rf.getRequest('AuthRequest').getQuestionGroups(self.params)
        self.groups = res.data
      } catch (e) {
        console.log(e)
      }
    },
    gotoPersonDetail () {
      let self = this
      self.$router.push({ path: '' })
    },
    formatPhoneNumber (number) {
      let cleaned = ('' + number).replace(/\D/g, '')
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        let hiden_number = ''
        for (var i = 0; i < match[2].length; i++) {
          hiden_number += 'X'
        }
        return match[1] + '-' + hiden_number + '-' + match[3]
      };
      return null
    },
    goToDetail (id, name) {
      // console.log(contact)
      let self = this
      if (self.isDoctor()) {
        self.$router.push({ path: `/doctor/person/${self.$route?.params?.id}/question?type=${id}` }).catch(() => { })
      } else if (self.isAdmin()) {
        self.$router.push({ path: `/admin/person/${self.$route?.params?.id}/question?type=${id}` }).catch(() => { })
      }
    },
    getImageURL (path) {
      return process.env.VUE_APP_BASE_IMAGE_URL + 'storage/' + path
    },
    formatDateDDMMYYY (time) {
      return window.moment(time).format('DD/MM/YYYY HH:mm')
    }
  }
}
</script>
<style scoped>
</style>