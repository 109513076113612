<template>
  <div
    v-loading="isLoading"
    class="py-4 w-full container flex flex-col justify-between"
  >
    <div class="flex items-center">
      <span class="title text-2xl text-black mr-2">
        {{ $t("lbl_patient") }}
      </span>
      <!-- <template v-if="isHasEHCSync">
        <el-tooltip class="item" effect="dark" content="Đồng bộ dữ liệu từ EHC" placement="top">
          <el-button
            size="small"
            :class="{
              disabled: isSyncing,
            }"
            :disabled="isSyncing"
            @click="handleSyncEHC()"
          >
            <span v-if="!isSyncing">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-refresh-cw"
              >
                <polyline points="23 4 23 10 17 10" />
                <polyline points="1 20 1 14 7 14" />
                <path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15" />
              </svg>
            </span>
            <i v-else class="el-icon-loading text-xl" />
          </el-button>
        </el-tooltip>
      </template> -->
    </div>
    <div class="row items-center lg:items-end py-3">
      <div class="input-group input-search-box w-2/3 lg:w-1/3 col-4">
        <span
          @click="searchContact()"
          class="input-group-text bg-white border-0 radius-10 font-size-20 cursor-pointer"
        >
          <i class="fa fa-search"></i>
        </span>
        <input
          type="search"
          v-model="search"
          class="form-control bg-white"
          :placeholder="
            $t(
              'lbl_name__phone_number__identity_card__social_healthcare_insurance'
            )
          "
          @keyup.enter="searchContact()"
          @keyup.delete="searchContactDelete()"
        />
        <span
          :class="!search ? 'disabled' : ''"
          @click="(search = ''), searchContact()"
          class="input-group-text bg-white border-0 radius-10 cursor-pointer"
        >
          <i class="fa fa-times-circle"></i>
        </span>
      </div>
      <div class="col-md-2 flex-1">
        <el-select
          @change="getPersonDetail"
          v-model="connect_status"
          :placeholder="$t('lbl_select_connection_status')"
        >
          <el-option
            v-for="item in PERSON_CONNECT_STATUS"
            :key="item.value"
            :label="$t(`${item.label}`)"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>

      <div class="btn-group col-xl-6">
        <el-button
          type="plain"
          icon="el-icon-download"
          class="text-blue-800"
          @click="getAllPersonByWsId"
          >{{ $t("lbl_export_data") }}</el-button
        >
        <el-button
          type="plain"
          icon="el-icon-upload"
          class="text-blue-800"
          @click="onShowModalUploadPerson"
          >{{ $t("lbl_upload_patient_list") }}</el-button
        >
        <el-button type="primary" @click="onShowModalAddPerson"
          >+ {{ $t("lbl_add_patient") }}</el-button
        >
      </div>
    </div>

    <div class="overflow-auto">
      <el-table
        :data="contacts.data"
        style="min-width: 1000px; max-width: unset"
        :row-class-name="tableRowClassName"
        header-cell-class-name="text-black text-sm font-semibold"
        @row-click="goToViewDetail"
      >
        <el-table-column
          prop="name"
          :label="$t('lbl_full_name')"
        ></el-table-column>
        <el-table-column
          prop="pid"
          :label="$t('lbl_patient_code')"
        ></el-table-column>
        <el-table-column
          :formatter="formatDMY"
          :label="$t('lbl_date_of_birth')"
        ></el-table-column>
        <el-table-column
          prop="phone"
          :label="$t('lbl_phone_number')"
        ></el-table-column>
        <el-table-column
          prop="address"
          :label="$t('lbl_address')"
        ></el-table-column>
        <el-table-column :label="$t('lbl_connection_status')">
          <template slot-scope="scope">
            <span
              class="label-approve"
              :class="statusConnectOrgVariant(scope.row).color"
              >{{ $t(statusConnectOrgVariant(scope.row).text) }}</span
            >
          </template>
        </el-table-column>
        <el-table-column :label="$t('lbl_action')" width="250px">
          <template slot-scope="scope">
            <div class="flex flex-col" style="flex-wrap: wrap; gap: 8px">
              <el-button
                class="ml-0"
                size="mini"
                @click.stop="showModalAddEMR(scope.row)"
              >
                <span class="text-blue-800">{{ $t("lbl_add_emr") }}</span>
              </el-button>
              <el-button
                class="ml-0"
                @click.stop="addHr(scope.row)"
                size="mini"
              >
                <span class="text-blue-800">
                  {{ $t("lbl_add_outpatient") }}
                </span>
              </el-button>
              <el-button
                class="ml-0"
                size="mini"
                @click="goToCreateCarePlanF(scope.row)"
              >
                <span class="text-blue-800">
                  {{ $t("lbl_add_health_record") }}
                </span>
              </el-button>
              <!-- <el-button class="ml-0" size="mini" @click.stop="handleTriggerExamination(scope.row)">
                <span class="text-blue-800">{{ $t('Tạo lượt khám') }}</span>
              </el-button>-->
              <el-button
                v-if="scope.row.is_new_ver === PATIENT_SYNC_HIS.isNew"
                class="ml-0 cs-bg-highlight text-blue-800 flex items-center gap-4 justify-center"
                size="mini"
                @click.stop="handleSyncPatient(scope.row)"
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 4V1L8 5L12 9V6C15.31 6 18 8.69 18 12C18 13.01 17.75 13.97 17.3 14.8L18.76 16.26C19.54 15.03 20 13.57 20 12C20 7.58 16.42 4 12 4ZM12 18C8.69 18 6 15.31 6 12C6 10.99 6.25 10.03 6.7 9.2L5.24 7.74C4.46 8.97 4 10.43 4 12C4 16.42 7.58 20 12 20V23L16 19L12 15V18Z"
                    fill="currentColor"
                  />
                </svg>
                <span class="ml-2">{{ $t("lbl_sync_from_HIS") }}</span>
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <AppPagination
      :paginationProps="paginationProp"
      @onChangePagination="getPersonDetail"
    />
    <!-- <Pagination :items="paginationProp" :current_page="current_page" @onRefresh="onRefresh"></Pagination> -->
    <ModalPatientInfo
      ref="ModalPatientInfo"
      @refresh="getPersonDetail"
    ></ModalPatientInfo>
    <ModalUploadPatient
      ref="ModalUploadPatient"
      @import="onRefresh({ page: 1 })"
    ></ModalUploadPatient>
    <ModalAddHR ref="ModalAddHR" />
  </div>
</template>
<script>
import { AppPagination } from '@/components/Common'
import ExcelJS from 'exceljs'
import ModalPatientInfo from '../../components/Patient/ModalPatientInfo.vue'
import ModalUploadPatient from '../../components/Person/ModalUploadPatient'
import appUtils from '../../utils/appUtils'
import { EXAMINATION_STATUS, PATIENT_SYNC_HIS } from '../../utils/constants'
import ModalAddHR from '../HealthRecords/ModalAdd.vue'

export default {
  components: {
    AppPagination,
    ModalPatientInfo,
    ModalAddHR,
    ModalUploadPatient
  },
  name: 'ContactList',
  data () {
    const PERSON_CONNECT_STATUS = [
      {
        label: 'lbl_connection_status',
        value: 0
      },
      {
        label: 'lbl_connection_required',
        value: 1
      },
      {
        label: 'lbl_connected',
        value: 2
      },
      {
        label: 'lbl_not_connected',
        value: 5
      },
      {
        label: 'lbl_denied',
        value: 3
      }
    ]

    return {
      appUtils,
      contacts: {},
      search: null,
      params: {
        page_num: 1,
        page_size: 10,
        // Change it to 147 later on, so that we can download it in full details
        order: 'desc',
        sort_by: 'updated_at'
      },
      paginationProp: {
        currentPage: 1,
        pageSize: 10,
        totalItems: 0
      },
      dataResExport: [],
      PERSON_CONNECT_STATUS,
      PATIENT_SYNC_HIS,
      connect_status: PERSON_CONNECT_STATUS[0].value,
      isLoading: false,
      isSyncing: false
    }
  },
  computed: {
    isHasEHCSync () {
      const settings = appUtils.isJSON(this.$globalClinic?.settings)
        ? JSON.parse(this.$globalClinic?.settings)
        : {}
      return !!settings?.ehc_config
    }
  },
  watch: {},
  mounted () {
    this.paginationProp.currentPage =
      Number(parseInt(this.$route.query.page)) || 1
    this.getPersonDetail()
    this.getConsultingRooms()
  },
  methods: {
    statusConnectOrgVariant (person) {
      let label_color
      let lable_text
      switch (person.patient_connect_request?.status) {
        case 1:
          label_color = 'bc-green'
          lable_text = 'lbl_connection_required'
          break
        case 2:
          label_color = 'bc-green'
          lable_text = 'lbl_connected'
          break
        case 3:
          label_color = 'bc-red'
          lable_text = 'lbl_denied'
          break
        default:
          label_color = 'bc-yellow'
          lable_text = 'lbl_not_connected'
          break
      }
      return { color: label_color, text: lable_text }
    },
    formatDMY (row) {
      return row.birthday && window.moment(row.birthday).format('DD-MM-YYYY')
    },
    addHr (obj) {
      this.$refs.ModalAddHR.openDialog(obj, 'create')
    },
    goToViewDetail (row) {
      const id = row.id
      self.$router.push({
        path: `/doctor/contact-detail/${id}?type=person-info`
      })
    },
    goToCreateCarePlanF (person) {
      this.goToCreateCarePlanS(person)
      window.event.stopPropagation()
    },
    goToCreateCarePlanS (person) {
      let self = this
      if (!person || !person.id) return
      if (person.person_diseases && person.person_diseases.length) {
        self.$router.push({
          path: `/doctor/contact-detail/${person.id}?type=person-info`
        })
      } else {
        self.$router
          .push({ path: `/doctor/diseases/${person.id}/create` })
          .catch((_) => {})
        setTimeout(() => {
          self.sendAmplitude('Add Hồ sơ sức khỏe For Contact', {
            'doctor id':
              self.user && self.user.doctor && self.user.doctor.id
                ? self.user.doctor.id
                : '',
            'doctor name': self.user && self.user.name ? self.user.name : '',
            'workspace id': self.clinic && self.clinic.id ? self.clinic.id : '',
            'workspace name':
              self.clinic && self.clinic.name ? self.clinic.name : '',
            timestamp: window.moment(new Date()).format('HH:mm DD-MM-YYYY'),
            'patient id': person.id,
            'patient name': person.name
          })
        }, 100)
      }
    },
    onShowModalAddPerson () {
      this.$refs.ModalPatientInfo.openDialog()
    },
    onShowModalUploadPerson () {
      this.$refs.ModalUploadPatient.openDialog()
    },
    searchContact () {
      this.params.search = this.search
      this.$router.replace({ query: { page_num: 1 } })
      this.getPersonDetail()
    },
    searchContactDelete () {
      if (this.search.length === 0) {
        this.$router.replace({ query: { page_num: 1 } })
        this.getPersonDetail()
      }
    },
    onRefresh ({ page }) {
      this.$route.query.page_num = page
      this.params = {
        ...this.params,
        page_num: page
      }
      this.getPersonDetail()
    },
    async getPersonDetail () {
      const params = {
        ...this.params,
        //   org_id: appUtils.getDoctorClinic().org_id,
        ws_id: this.$globalClinic?.id,
        page_num: this.paginationProp.currentPage,
        page_size: this.paginationProp.pageSize,
        keyword: this.search,
        sort: 'updated_at',
        order: 'desc',
        connect_status: this.connect_status
      }
      if (!params.connect_status) delete params.connect_status

      await this.$rf
        .getRequest('DoctorRequest')
        .getPersonList(params)
        .then((res) => {
          this.contacts = res.data
          this.paginationProp = {
            totalItems: res.data.page.total,
            pageSize: res.data.page.page_size,
            currentPage: res.data.page.page_num
          }
        })
    },
    async getAllPersonByWsId () {
      const params = {
        page_num: this.paginationProp.currentPage,
        page_size: 10000,
        order: 'desc',
        sort_by: 'updated_at',
        ws_id: this.$globalClinic?.id,
        sort: 'updated_at',
        keyword: this.search
      }
      await this.$rf
        .getRequest('DoctorRequest')
        .getPersonList(params)
        .then((res) => {
          this.dataResExport = res.data
          console.log(this.dataResExport)
          this.exportExcel()
        })
    },
    async exportExcel () {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Sheet1', {
        views: [{ showGridLines: false }],
        pageSetup: {
          horizontalCentered: true,
          verticalCentered: true
        }
      })

      // Access columns from B to J and set their widths
      const defaultColWidth = 20
      for (let index = 1; index <= 26; index++) {
        const column = worksheet.getColumn(index + 1) // Columns are 1-indexed
        if ([1, 4, 7].includes(index)) {
          column.width = 28
        } else {
          column.width = defaultColWidth
        }
      }

      worksheet.properties.defaultRowHeight = 14
      const START_ROW = 1

      // Add a header rows and merged cells with style before main table
      const nameCell = worksheet.getCell(`D${START_ROW}`)
      nameCell.value = 'Danh Sách Bệnh Nhân'
      nameCell.font = { size: 20, bold: true }
      worksheet.mergeCells(`D${START_ROW}:G${START_ROW}`)

      //   const inventoryNameCell = worksheet.getCell(`B${START_ROW + 1}`);
      //   inventoryNameCell.value = this.inventoryName?.toUpperCase();
      //   inventoryNameCell.font = { size: 12, bold: true };
      //   worksheet.mergeCells(`B${START_ROW + 1}:E${START_ROW + 1}`);

      //   const dateCell = worksheet.getCell(`B${START_ROW + 2}`);
      //   dateCell.value = `Từ ${this.fromDate} đến ${this.toDate}`;
      //   dateCell.font = { bold: false, italic: true };
      //   worksheet.mergeCells(`B${START_ROW + 2}:E${START_ROW + 2}`);
      const tableHeader = [
        'STT',
        'Họ Tên',
        'Mã bệnh nhân',
        'Ngày sinh',
        'Số ĐT',
        'Địa chỉ',
        'Địa chỉ huyện',
        'Địa chỉ tỉnh',
        'Địa chỉ phường'
      ]
      // Generate the main table (your data) with borders and style
      const tableData = this.dataResExport.data.map((item, i) => {
        return [
          i + 1,
          item.name || '',
          item.pid || '',
          this.formatDMY(item) || '',
          item.phone,
          item.address || '',
          item.address_district_name || '',
          item.address_province_name || '',
          item.address_wards_name || ''
        ]
      })

      const mainTableData = [
        [], // empty row
        tableHeader,
        ...tableData
      ]
      worksheet.addRows(mainTableData)
      const mainTable = worksheet.getRows(3, mainTableData.length - 1)
      mainTable.forEach((row, rowIndex) => {
        row.eachCell((cell) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
            bottom: { style: 'thin' }
          }
          cell.font = { size: 12 }
          if (!rowIndex) {
            cell.font = { bold: true }
          }
        })
      })
      // default styles
      workbook.eachSheet((sheet) => {
        sheet.eachRow((row) => {
          row.alignment = {
            horizontal: 'center',
            vertical: 'middle',
            wrapText: true
          }
          row.eachCell((cell) => {
            if (!cell.font?.name) {
              cell.font = Object.assign(cell.font || {}, {
                name: 'Times New Roman'
                // size: 12
              })
            }
          })
        })
      })

      // Save the workbook to a file
      await workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          `DanhSachBenhNhan_${window
            .moment(Date.now())
            .format('DD-MM-YYYY')}.xlsx`
        )
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    },
    showModalAddEMR (data) {
      if (!data.id) return
      this.$router.replace({
        name: 'ListBenhAn',
        query: {
          tab: 'list',
          perId: data.id,
          back: 'ContactList'
        }
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      let bgColor = ''

      if (row?.is_new_ver === PATIENT_SYNC_HIS.isNew) {
        // bgColor = 'cs-bg-highlight'
      }

      return `text-black text-sm cursor-pointer ${bgColor}`
    },
    async putSyncPatient (item) {
      try {
        await this.$rf.getRequest('DoctorRequest').putSyncPatient(item.doc_no)
        this.$toast.open({
          message: this.$t('Cập nhật thông tin bệnh nhân thành công'),
          type: 'success'
        })

        this.getPersonDetail()
      } catch (error) {
        this.$toast.open({
          message: this.$t('Có lỗi trong quá trình đồng bộ'),
          type: 'warning'
        })

        console.log(error)
      }
    },
    handleSyncPatient (data) {
      this.$confirm('Vui lòng xác nhận để đồng bộ thông tin bệnh nhân từ HIS', {
        confirmButtonText: 'Xác nhận'
      })
        .then((_) => {
          this.putSyncPatient(data)
        })
        .catch((_) => {})
    },
    async handleTriggerExamination (item) {
      try {
        this.isLoading = true
        const responseCreateAppt = await this.handleCreateAppointment(item)
        if (!responseCreateAppt) {
          this.handleShowErrorTriggerExamination()
          this.isLoading = false
          return
        }

        const responseConfirmAppt = await this.handleConfirmAppointment(
          item,
          responseCreateAppt
        )
        if (!responseConfirmAppt) {
          this.handleShowErrorTriggerExamination()
          this.isLoading = false

          return
        }

        const responseCreateExamination = await this.handleCreateExamination(
          item,
          responseConfirmAppt
        )
        if (!responseCreateExamination) {
          this.handleShowErrorTriggerExamination()
          return
        }
        this.$router.replace({
          name: 'PatientVisitDetail',
          params: {
            id: responseCreateExamination?.id
          }
        })
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    async handleCreateAppointment (item) {
      const params = {
        WorkspaceId: this.$globalClinic?.id,
        CrId: this.consulting?.[0]?.ID,
        DoctorID: this.$user?.doctor?.id,
        StartTime: window.moment().format('YYYY-MM-DD HH:mm:ss'),
        EndTime: window
          .moment()
          .add(5, 'minutes')
          .format('YYYY-MM-DD HH:mm:ss'),
        SlotPrice: 0,
        SlotDuration: null,
        PersonId: item?.id,
        Method: 1,
        LineNumber: 0
      }
      return this.$rf
        .getRequest('DoctorRequest')
        .postAppts(params)
        .then((resp) => {
          return resp.data?.data
        })
        .catch((e) => {
          console.log(e)
        })
    },
    async handleConfirmAppointment (item, responseCreateAppt) {
      const params = {
        clinic_id: this.$globalClinic?.id,
        cr_id: this.consulting?.[0]?.ConsultingRoomID,
        doctor_id: this.$user?.doctor?.id,
        person_id: item?.id,
        start_time: window.moment().format('YYYY-MM-DD HH:mm:ss'),
        end_time: window
          .moment()
          .add(5, 'minutes')
          .format('YYYY-MM-DD HH:mm:ss'),
        appt_id: responseCreateAppt?.id,
        method: 1,
        checkin_time: window.moment(),
        visit_reason: ''
      }

      try {
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .setPatientVisits(params)

        return response.data
      } catch (error) {
        console.log(error)
      }
    },
    async handleCreateExamination (item, responseConfirmAppt) {
      try {
        const params = {
          appt_id: responseConfirmAppt?.appt_id,
          clinic_id: responseConfirmAppt?.clinic_id,
          doctor_id: responseConfirmAppt?.doctor_id,
          cr_id: responseConfirmAppt?.cr_id,
          person_id: responseConfirmAppt?.person_id,
          start_time: responseConfirmAppt?.start_time,
          end_time: responseConfirmAppt?.end_time,
          checkin_time: responseConfirmAppt?.checkin_time,
          status: EXAMINATION_STATUS.CODE.BEING
        }

        const response = await this.$rf
          .getRequest('DoctorRequest')
          .updatePatientVisitsById(responseConfirmAppt?.id, params)

        return response.data
      } catch (error) {
        console.log(error)
      }
    },
    handleShowErrorTriggerExamination () {
      this.isLoading = false
      this.$message({
        type: 'error',
        message: this.$t('Có lỗi xảy ra khi tạo lượt khám')
      })
    },
    async getConsultingRooms () {
      const params = {
        limit: 10,
        page: 1,
        Date: window.moment().format('YYYY-MM-DD'),
        CurrentTime: window.moment().format('YYYY-MM-DD HH:mm')
      }

      try {
        let resp = await this.$rf
          .getRequest('DoctorRequest')
          .getConsultingRoomWithDoctor(params, this.$globalClinic?.id)
        this.consulting = resp.data.data || []
      } catch (e) {
        // statements
        console.log(e)
      } finally {
      }
    },
    async handleSyncEHC () {
      const self = this
      try {
        self.isSyncing = true
        const params = {
          org_id: self.$globalClinic?.org_id,
          ws_id: self.$globalClinic?.id
        }
        await self.$rf
          .getRequest('EHCSyncRequest')
          .ehcSyncPatients(params)
          .then((res) => {
            if (!res?.data) return
            if (res?.data?.code === 401) {
              self.$message({
                type: 'error',
                message: self.$t(`Bạn không thể đồng bộ dữ liệu từ EHC`)
              })
              console.log(res)
              return
            }
            console.log(res?.data)
            self.onRefresh({ page: 1 })
            self.$message({
              type: 'success',
              message: self.$t(`Đồng bộ dữ liệu thành công`)
            })
            self.isSyncing = false
          })
      } catch (error) {
        console.log(error)
      }
    }
    // handleChangePagination (data) {
    //   console.log(data)
    //   this.paginationProp={
    //     ...this.paginationProp,

    //   }

    // }
  }
}
</script>
<style lang="scss" scoped>
.label-approve {
  white-space: nowrap;
  font-size: 12px;
  font-weight: 600;
  padding: 2px 15px;
  border: 1px solid red;
  border-radius: 10px;
  &.bc-green {
    border-color: #209b3b;
  }
  &.bc-yellow {
    border-color: #f9b915;
  }
  &.bc-red {
    border-color: #ff0d0d;
  }
}

::v-deep {
  .el-input__inner {
    background-color: white !important;
  }

  .cs-bg-highlight {
    background-color: #fef0c7 !important;

    & *,
    &:hover * {
      background-color: #fef0c7 !important;
    }
  }
}
</style>
