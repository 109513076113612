<template>
  <div>
    <div class="telemedicine-management pr-3">
      <div class="row pl-3">
        <div class="col-12">
          <div class="d-flex py-1 align-items-center">
            <span class="txt-pri page-title">Danh sách Workspace</span>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="row justify-content-lg-between">
            <div class="col-12 col-lg-auto mb-1">
              <div class="d-flex">
                <input class="form-control mr-sm-2 bg-white" type="search" placeholder="Search" aria-label="Search" v-model="search" @click.enter="getClinics" @click.delete="searchClinicsDelete">
                <button class="btn bg-pri bd-pri text-white my-2 my-sm-0" @click="getClinics">Tìm kiếm</button>
              </div>
            </div>
            <div class="col-12 col-lg-auto mb-1">
              <div class="d-flex h-100">
                <router-link to="/clinics/add" class="btn bg-pri bd-pri text-white">
                  <span class="fa fa-plus mr-1"></span>
                  Thêm Workspace
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card radius-10 pt-4 pl-4 pr-4 border-0">
        <div class="table-responsive">
          <table class="table table-vcenter table-mobile-md card-table table-scroll">
            <thead>
              <tr class="d-sm-flex">
                <th class="col-sm-4">Thông tin Workspace</th>
                <th class="col-sm-3">Liên hệ</th>
                <th class="col-sm-2">Trạng thái</th>
                <th class="col-sm-3"></th>
              </tr>
            </thead>
            <tbody v-if="clinics && clinics.count && clinics.data" class="h-100">
              <tr class="d-sm-flex" v-for="clinic in clinics.data" :key="clinic.id">
                <td class="col-sm-4" data-label="Thông tin Workspace">
                  <div class="d-flex lh-sm py-1 align-items-center">
                    <span v-if="clinic.avatar" class="avatar mr-2"
                    :style="'background-image: url(' + getImageURL(clinic.avatar) + ')'"></span>
                    <span v-if="!clinic.avatar"
                    class="avatar mr-2">{{ clinic.name ? clinic.name.charAt(0) : '' }}</span>
                    <div class="flex-fill">
                      <div class="strong">{{ clinic ? clinic.name : '' }}</div>
                      <div class="text-muted text-h5">ID: {{ clinic.id }} - <a href="javascript:;" class="text-reset">{{ clinic ?
                      clinic.code : '' }}</a></div>
                    </div>
                  </div>
                </td>
                <td class="col-sm-3" data-label="Liên hệ">
                  <div>{{ clinic.address }}</div>
                  <div class="text-muted text-h5">{{ clinic.phone }}</div>
                </td>
                <td class="text-muted col-sm-2" data-label="Trạng thái">
                  <span v-if="clinic.status !== 1" class="text-danger">Inactive</span>
                  <span v-if="clinic.status === 1" class="text-success">Active</span>
                </td>
                <td class="col-sm-3">
                  <a href="javascript:;" class="btn btn-sm bg-pri bd-pri text-white" @click="showChangeWsFeature(clinic)">Cài đặt tính năng</a>
                  <router-link class="h2 d-inline m-1 icon-action-table" :to="'/clinics/' + clinic.id + '/edit'">
                    <button class="btn btn-sm btn--bg-success btn--person">
                      <div class="btn--edit"></div>
                    </button>
                  </router-link>
                  <a href="javascript:;" class=" h2 d-inline m-1 icon-action-table" @click="onRemoveClinic(clinic.id)">
                    <button class="btn btn-sm btn--bg-danger btn--person">
                      <div class="btn--delete"></div>
                    </button>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          <Pagination v-if="clinics" :items="clinics" :current_page="current_page" @onRefresh="getClinics"></Pagination>
        </div>
        <div v-if="clinics && !clinics.count" class="row">
          <div class="col-sm-4 offset-sm-4 pt-3 pb-5 text-center">
            <img src="../../../public/assets/images/not-found-search.png" class="img-responsive p-0" alt="">
            <p class="robo-16-400 text-black">{{search ? 'HODO không tìm thấy thẻ tag mà bạn tìm' : 'Chưa có thẻ tag nào!' }}</p>
          </div>
        </div>
      </div>
    </div>
    <ModalChangeFeature :clinic="showing_ws"/>
  </div>
</template>
<script>
  import Pagination from '../../components/Pagination'
  import appUtils from '../../utils/appUtils'
  import ModalChangeFeature from '../../components/Cms/Workspace/ModalChangeFeature.vue'

  export default {
    name: 'ClinicListing',
    components: {Pagination, ModalChangeFeature},
    data () {
      return {
        clinics: null,
        current_page: 1,
        search: '',
        showing_ws: null
      }
    },
    mounted () {
      let self = this

      self.getClinics()
    },
    methods: {
      async getClinics () {
        let self = this
        self.current_page = parseInt(self.$route.query.page) || 1
        let params = {
          page: self.current_page
        }
        if (self.search) {
          params.search = self.search
        }

        let resp = await self.$rf.getRequest('AdminRequest').getClinics(params)
        self.clinics = resp.data
      },
      async onRemoveClinic (clinic_id) {
        let self = this
        var r = confirm('Do you want to remove this clinic?')
        if (!r) {
          return false
        }

        await self.$rf.getRequest('AdminRequest').deleteClinic(clinic_id)
      },
      async onLoginClinic (clinic_id) {
        let self = this
        var r = confirm('Do you want to login using this clinic account?')
        if (!r) {
          return false
        }

        self.loading = true
        window.localStorage.isAdmin = true
        let resp = await self.$rf.getRequest('AdminRequest').loginClinic(clinic_id)
        if (resp.data.access_token) {
          appUtils.removeLocalToken()
          appUtils.removeLocalUser()

          appUtils.setLocalToken(resp.data.access_token)
          resp = await self.$rf.getRequest('AuthRequest').me()
          if (resp) {
            appUtils.setLocalUser(resp.data)
          }

          self.loading = false

          setTimeout(() => {
            window.location.href = '/'
          }, 300)
        }
      },
      getImageURL (avatar) {
        return appUtils.getImageURL(avatar)
      },
      searchClinicsDelete () {
        let self = this
        if (!self.search) {
          self.getClinics()
        }
      },
      showChangeWsFeature (w) {
        this.showing_ws = w
        this.showModalChangeWsFeature(true)
      },
      showModalChangeWsFeature (s) {
        window.$('#ModalChangeFeature').modal(s ? 'show' : 'hide')
      }
    }
  }
</script>
