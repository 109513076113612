<template>
<div>
  <!-- Page title -->
  <div class="page-header">
    <div class="row align-items-center">
      <div class="col-auto">
        <h2 class="page-title">
          Thêm Workspace mới
        </h2>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-3"></div>
    <div class="col-sm-6">

    <ClinicForm :is-edit="false" :loading="loading" @onSubmit="onSubmit"></ClinicForm>

    </div>
    <div class="col-sm-3"></div>
  </div>
</div>
</template>
<script>
  import ClinicForm from '../../components/ClinicForm'

  export default {
    name: 'ClinicAdd',
    components: {ClinicForm},
    data () {
      return {
        loading: false
      }
    },
    methods: {
      async onSubmit (formData) {
        let self = this
        if (!formData) { return false }

        try {
          self.loading = true
          await self.$rf.getRequest('AdminRequest').postClinic(formData).then(res => {
            self.$router.push({path: `/clinics`}).catch(_ => {})
          })
          self.loading = false
        } catch (e) {
          self.loading = false
        }
      }
    }
  }
</script>
