<template>
  <div>
    <div class="telemedicine-management pr-3">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-12 mb-3">
              <Breadcrumb :data="getBreadcrumbData()"></Breadcrumb>
            </div>
            <div class="col-sm-12">
              <div class="row justify-content-lg-end">
                <div class="col-auto mr-auto">
                  <ul class="nav nav-tabs type-bar mb-1">
                    <li class="nav-item">
                      <a
                        href="javascript:;"
                        class="nav-link"
                        :class="type == 'person-info' ? 'active' : ''"
                        @click="changeType('person-info')"
                      >{{ $t('lbl_basic_information') }}</a>
                    </li>
                    <li class="nav-item">
                      <a
                        href="javascript:;"
                        class="nav-link"
                        :class="type == 'general-health' ? 'active' : ''"
                        @click="changeType('general-health')"
                      >{{ $t('lbl_general_health_check') }}</a>
                    </li>
                    <!-- <li class="nav-item">
                      <a
                        href="javascript:;"
                        class="nav-link"
                        :class="type == 'tree-family' ? 'active' : ''"
                        @click="changeType('tree-family')"
                      >{{ $t('Cây phả hệ') }}</a>
                    </li>-->
                    <!-- <li class="nav-item">
                      <a href="javascript:;" class="nav-link"  :class="type == 'medical-info' ? 'active' : ''"  @click="changeType('medical-info')">Thông tin khác</a>
                    </li>-->
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2">
        <div class="card radius-10 pl-4 pr-4 pt-4 border-0 row" v-if="type == 'person-info'">
          <ContactInfo></ContactInfo>
        </div>
        <div v-if="type == 'general-health'">
          <generalHealth></generalHealth>
        </div>
        <div class="w-full" style="height:80vh" v-loading="loading" v-if="type == 'tree-family'">
          <TreeFamily v-if="treeData" :treeData="treeData" />
        </div>
        <!-- <div v-if="type == 'medical-info'">
          <PersonalHealthInformation></PersonalHealthInformation>
        </div>-->
      </div>
    </div>
  </div>
</template>
<script>
import Breadcrumb from '../../components/Breadcrumb'
import PersonalHealthInformation from './_PersonalHealthInformation.vue'
import generalHealth from './generalHealth.vue'
import ContactInfo from './ContactInfo.vue'
import TreeFamily from '../../pages/TreeFamily/index.vue'

export default {
  name: 'ContactDetail',
  components: {
    Breadcrumb,
    PersonalHealthInformation,
    ContactInfo,
    generalHealth,
    TreeFamily
  },
  computed: {},
  data () {
    return {
      root_type: 1,
      person_id: 0,
      person: {},
      request_list: [],
      appoiments: [],
      request: {},
      edit_birth: '',
      edit_person: {},
      loading: false,
      user: null,
      clinic: null,
      appointment: null,
      type: 'person-info',
      treeData: null
    }
  },
  mounted () {
    let self = this
    self.person_id = self.$route?.params?.id
    self.type = self.$route.query.type
    this.changeType(self.type)
    setTimeout(() => {
      this.sendAmplitude('View Contact Detail', {
        'doctor id':
          this.user && this.user.doctor && this.user.doctor.id
            ? this.user.doctor.id
            : '',
        'doctor name': this.user && this.user.name ? this.user.name : '',
        'workspace id': this.clinic && this.clinic.id ? this.clinic.id : '',
        'workspace name':
          this.clinic && this.clinic.name ? this.clinic.name : '',
        timestamp: window.moment(new Date()).format('HH:mm DD-MM-YYYY'),
        'patient id':
          this.$route && this.$route.params && this.$route?.params?.id
            ? this.$route?.params?.id
            : '',
        'patient name': this.person && this.person.name ? this.person.name : ''
      })
    }, 100)
  },
  watch: {
    '$route.query.type': {
      handler: function (data) {
        let self = this
        self.person_id = self.$route?.params?.id
        // self.onRefresh()
      },
      deep: true
    }
  },
  methods: {
    changeType (type) {
      let self = this
      self.type = type
      self.$router.replace({ query: { type: type } }).catch(() => {})

      if (type === 'tree-family') {
        this.handleGetTreeData()
      }
    },
    getBreadcrumbData (name) {
      var data = {
        icon: 'contact.svg',
        pages: [
          {
            text: this.$t('lbl_patient'),
            active: false,
            path: '/doctor/contact-management',
            prev: false
          },
          { text: this.$t('lbl_patient_detail'), active: true }
        ]
      }
      return data
    },
    async handleGetTreeData () {
      try {
        this.loading = true

        const params = {
          person_id: 6820,
          sort_by: 'created_at',
          order: 'desc',
          page_num: 1,
          page_size: 1000
        }

        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getRelationshipByPID(params)

        const responseData = response.data?.data || []
        const formatData = responseData?.map(item => {
          const is_root = item?.peer_pid === item?.root_pid

          const data = {
            name: is_root ? `${item?.person?.name}(*)` : item?.person?.name,
            gender: item?.person?.gender === 1 ? 'M' : 'F',
            birthday: item?.person?.birthday
              ? window.moment(item?.person?.birthday).format('DD-MM-YYYY')
              : '',
            is_root
          }
          const rels = {}

          if (item?.father) {
            rels.father = Number(item?.father)
          }

          if (item.mother) {
            rels.mother = Number(item?.mother)
          }

          if (item?.spouses?.length) {
            rels.spouses = item?.spouses?.map(spouseItem => Number(spouseItem))
          }

          if (item?.children?.length) {
            rels.children = item?.children?.map(childItem => Number(childItem))
          }

          const id = item?.peer_pid
          const real_id = item?.id

          return {
            ...item,
            id,
            real_id,
            rels,
            data
          }
        })
        console.log(formatData)

        this.treeData = formatData
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style lang="css" scoped>
</style>