var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"telemedicine-management contact-list pr-3"},[_c('div',{staticClass:"card radius-10 pt-2 pl-4 pr-4 border-0"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-vcenter table-mobile-md card-table table-scroll"},[_vm._m(0),(_vm.groups && _vm.groups.count && _vm.is_loaded)?_c('tbody',{class:_vm.groups && _vm.groups.count == 10 ? 'h-100' : '',attrs:{"id":"contacts"}},[_vm._l((_vm.groups.data),function(group,index){return _c('tr',{key:'gr'+index,staticClass:"mt-2 table-tr",staticStyle:{"height":"70px"},on:{"click":function($event){return _vm.goToDetail(group.id, group.name)}}},[_c('td',{attrs:{"data-label":"Bệnh sử"}},[_c('span',{staticClass:"robo-20-500 pl-2"},[_vm._v(_vm._s(group && group.name))])]),_c('td',{staticClass:"text-sm-center",staticStyle:{"text-transform":"capitalize"}}),_c('td',{staticClass:"text-sm-center"})])}),_c('tr',[(_vm.groups && !_vm.groups.data && _vm.is_loaded)?_c('td',{attrs:{"colspan":"3"}},[_c('div',{staticClass:"col-sm-4 offset-sm-4 pt-3 pb-5 text-center"},[_c('img',{staticClass:"img-responsive p-0",attrs:{"src":require("../../../public/assets/images/not-found-search.png"),"alt":""}}),_c('p',{staticClass:"robo-16-400 text-black"},[_vm._v(_vm._s(_vm.search ? 'HODO không tìm thấy kết quả phù hợp' : 'Bạn chưa có bệnh nhân thân thiết nào'))])])]):_vm._e()])],2):_vm._e(),(!_vm.is_loaded)?_c('tbody',_vm._l((10),function(i){return _c('tr',{key:'loading' + i},[_vm._m(1,true),_vm._m(2,true),_vm._m(3,true),_vm._m(4,true),_vm._m(5,true)])}),0):_vm._e()])]),_c('br')])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"d-none d-md-table"},[_c('tr',[_c('th',[_vm._v("Nhóm thông tin")]),_c('th',{staticClass:"text-sm-center"}),_c('th',{staticClass:"text-sm-center"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"skeleton-avatar avatar-sm avatar-rounded"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"skeleton-line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"skeleton-line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"skeleton-line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"skeleton-line"})])
}]

export { render, staticRenderFns }