<template>
<div class="p-3">
  <!-- Page title -->
  <div class="page-header">
    <div class="row align-items-center">
      <div class="col-auto">
        <h2 class="page-title">
          {{ $t('title.info.clinic')[$orgModel] }}
        </h2>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">

    <ClinicForm v-if="clinic" :is-edit="true" :inputs="clinic" :loading="loading" @onSubmit="onSubmit"></ClinicForm>

    </div>
  </div>
</div>
</template>
<script>
  import ClinicForm from '../../components/ClinicForm'

  export default {
    name: 'ClinicEdit',
    components: {ClinicForm},
    data () {
      return {
        loading: false,
        clinic: null,
        clinic_id: 0
      }
    },
    async mounted () {
      let self = this

      self.clinic_id = self.$route?.params?.id
      if (self.clinic_id) {
        let resp = await self.$rf.getRequest('AdminRequest').getClinicDetail(self.clinic_id)
        self.clinic = resp.data
      }
    },
    methods: {
      async onSubmit (formData) {
        let self = this
        if (!formData) { return false }

        try {
          self.loading = true
          await self.$rf.getRequest('AdminRequest').postClinicUpdate(self.clinic_id, formData)
          self.$router.go(-1)
          self.loading = false
        } catch (e) {
          self.loading = false
        }
      }
    }
  }
</script>
